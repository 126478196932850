/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Messages', route: { name: 'bulk-message' }, icon: 'MessageSquareIcon', isBookmarked: false },
      { title: 'View Inbox', route: { name: 'inbox' }, icon: 'MessageSquareIcon', isBookmarked: false },
     // { title: 'SMS Analytics', route: { name: 'sms-analytic' }, icon: 'PieChartIcon', isBookmarked: false },
     // { title: 'View Keywords', route: { name: 'keyword' }, icon: 'TagIcon', isBookmarked: false },
     // { title: 'View SMPP', route: { name: 'smpp' }, icon: 'CreditCardIcon', isBookmarked: false },
      //{ title: 'Delivery Callback URL', route: { name: 'callback-dlr' }, icon: 'PlusIcon', isBookmarked: false },
      //{ title: 'Incoming Callback URL', route: { name: 'callback-incoming' }, icon: 'MailIcon', isBookmarked: false },
      //{ title: 'Subscription Callback URL', route: { name: 'callback-subscription' }, icon: 'AlignLeftIcon', isBookmarked: false },
      { title: 'Alphanumeric', route: { name: 'alphanumerics' }, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'Sender Id', route: { name: 'alphanumerics' }, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'Shortcodes', route: { name: 'shortcode' }, icon: 'SettingsIcon', isBookmarked: false },
      //{ title: 'Transactions', route: { name: 'payments-transactions' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Premium Outbox', route: { name: 'premium-outbox' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      //{ title: 'Payment Analytics', route: { name: 'analytics-payment' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'USSD Application', route: { name: 'request' }, icon: 'PlusIcon', isBookmarked: false },
      { title: 'Shortcode Application', route: { name: 'request' }, icon: 'PlusIcon', isBookmarked: false },
      { title: 'Sender Id Application', route: { name: 'request' }, icon: 'PlusIcon', isBookmarked: false },
      { title: 'USSD Services Code', route: { name: 'code-ussd' }, icon: 'ShieldIcon', isBookmarked: false },
      //{ title: 'Users Edit', route: { name: 'apps-users-edit', params: { id: 21 } }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Users List', route: { name: 'apps-users-list' }, icon: 'UserIcon', isBookmarked: false },
      //{ title: 'Users View', route: { name: 'apps-users-view', params: { id: 21 } }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Invoice Add', route: { name: 'apps-invoice-add' }, icon: 'FileTextIcon', isBookmarked: false },
      //{ title: 'Invoice Edit', route: { name: 'apps-invoice-edit', params: { id: 4987 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice List', route: { name: 'apps-invoice-list' }, icon: 'FileTextIcon', isBookmarked: false },
      //{ title: 'Invoice Preview', route: { name: 'apps-invoice-preview', params: { id: 4987 } }, icon: 'FileTextIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Contact Upload Sample",
        from: 'Liden Solution ',
        file_url: 'https://lidenapp.s3.eu-central-1.amazonaws.com/liden_bulk_sms_file_upload.csv',
        icon: require('@/assets/images/icons/doc.png'),
        size: '89 Bytes',
      },
      {
        file_name: 'Shortcode Application Template',
        file_url: 'http://104.155.59.91/liden-main/files/AuthorizationLetterSafaricom.docx',
        from: 'Liden Solution',
        icon: require('@/assets/images/icons/doc.png'),
        size: ' 52 kb',
      },
      {
        file_name: 'Bulk Upload Sample',
        from: 'Liden Solution',
        file_url: 'https://lidenapp.s3.eu-central-1.amazonaws.com/liden_bulk_sms_file_upload.csv',
        icon: require('@/assets/images/icons/doc.png'),
        size: '89 Bytes',
      },
      {
        file_name: 'Airtime Upload Sample',
        from: 'Liden Solution',
        file_url: 'http://104.155.59.91/liden-main/files/Liden_airtime_sample.csv',
        icon: require('@/assets/images/icons/doc.png'),
        size: '2.3 mb',
      },
      
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: "",
        name: 'Nancy',
        email: 'nancy@liden.co.ke',
        time: '21/05/2021',
      },
    ],
  },
}
/* eslint-enable */
