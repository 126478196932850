export default [
  {
    header: 'Stats',
  },
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Analytics',
        route: 'home',
      },
    ],
  },
 
  {
    header: 'Apps',
  },
  {
    title: 'SMS',
    icon: 'MessageSquareIcon',
    tagVariant: 'light-warning',
    children: [
      // {
      //   title: 'Request',
      //   route: 'request',
      // },
      
      {
        title: 'Bulk SMS',
        children: [
          {
            title: 'Outbox',
            route: 'bulk-message',
          },
          
          {
            title: 'Analytic',
            route: 'sms-analytic',
          },
          {
            title: 'Message',
            route: 'bulk-message-list',
          },
          {
            title: 'Blacklist',
            route: 'blacklist-view',
          },
        ],
      },
      {
        title: 'Premium',
        children: [
          {
            title: 'Outbox',
            route: 'premium-outbox',
          },
          // {
          //   title: 'Sub Product',
          //   route: 'premium-product',
          //  },
          // {
          //   title: 'Analytic',
          //   route: 'premium-analytic',
          // },
        ],
      },
      {
        title: 'Shortcode',
        children: [
          {
            title: 'My Shortcode',
            route: 'shortcode',
          },
        ],
      },
      {
        title: 'Alphanumeric',
        children: [
          {
            title: 'Alphanumeric',
            route: 'alphanumerics',
          },
        ],
      },
      
      // {
      //   title: 'Inbox',
      //   children: [
      //     {
      //       title: 'Shortcode',
      //       route: 'apps-inbox',
      //     },
          
      //   ],
      // },
      // {
      //   title: 'SMPP Accounts',
      //   route: 'smpp',
      // },
      // {
      //   title: 'SMS Callback',
      //   children: [
      //     {
      //       title: 'Delivery URL',
      //       route: 'callback-dlr',
      //     },
      //     {
      //       title: 'Incoming Message',
      //       route: 'callback-incoming',
      //     },
      //     {
      //       title: 'Sub Notification',
      //       route: 'callback-subscription',
      //     },
      //   ],
      // },
    ],
  },
  //
  {
    title: 'Inbox',
    icon:  'CheckSquareIcon',
    route: 'apps-inbox',
  }, 
  
  {
    title: 'Contact',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'View Contacts',
        route: 'apps-contact-filter',
      },
    ]
  },
  {
    title: 'Survey',
    icon: 'BarChartIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'View Stats',
        route: 'survey-dashboard',
      },
      {
        title: 'Create Survey',
        route: 'survey-create',
      },
    ]
  },
  {
    title: 'USSD',
    icon: 'CheckSquareIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Services Code',
        route: 'code-ussd',
      },
      // {
      //   title: 'Request',
      //   route: 'request',
      // },
      // {
      //   title: 'Session',
      //   route: 'session-ussd',
      // },
      // {
      //   title: 'Analytics',
      //   route: 'analytics-ussd',
      // },
    ]
  },
  {
    title: 'Airtime',
    icon: 'FileIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Transation',
        route: 'airtime-transaction',
      },
      // {
      //   title: 'Analytics',
      //   route: 'analytics-airtime',
      // },
    ]
  },
  // {
  //   title: 'Payments',
  //   icon: 'PieChartIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'Product',
  //       route: 'payments-products',
  //     },
  //     {
  //       title: 'Transaction',
  //       route: 'payments-transactions',
  //     },
  //     {
  //       title: 'Analytics',
  //       route: 'analytics-payment',
  //     },
  //   ]
  // },
  {
    title: 'Billing',
    icon: 'ShoppingCartIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Payment Method',
        route: 'billing-payment-methods',
      },
      {
        title: 'Top ups',
        route: 'billing-tops',
      },
      {
        title: 'Expenditures',
        route: 'billing-expenditure',
      },
      {
        title: 'Refunds',
        route: 'billing-refund',
      },
      
    ]
  },
  {
    header: 'Settings',
  },
  {
    title: 'Services Request',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'My Request',
        route: 'view-request',
      },
      {
        title: 'Apply Request',
        route: 'request',
      },
    ],
  },
  // {
  //   title: 'Developers',
  //   icon: 'SettingsIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'API key',
  //       route: 'dev-api',
  //     },
  //     {
  //       title: 'Balance Alert',
  //       route: 'balance-alert',
  //     },
  //   ],
  // },
  {
    title: 'BulkRate Card',
    icon: 'ServerIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'View Bulk Rate',
        route: 'bulk-rate-card',
      },
    ]
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'View Invoices',
        route: 'apps-invoice-list',
      },
    ],
  },
  {
    title: 'User',
    icon: 'UserIcon',
    children: [
      {
        title: 'View Users',
        route: 'apps-users-list',
      },
    ],
  },
]
