<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <b-button
      variant="gradient-danger"
      style="margin-left:5px"
      @click="goMessage" 
    >
      <feather-icon
        icon="MessageSquareIcon"
        class="mr-50"
        
      />
      <span class="align-middle">Compose Message</span>
    </b-button>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{mobile}}
            </p>
            <span class="user-status">{{accessName}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center"
        :to="{ name: 'account-setting' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        
        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
            
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    SearchBar,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
      return {
          clientName: '',
          email: '',
          mobile: '',
          accessName: '',
          avatar:'',
          shallShowEmailComposeModal:false
      }
  },
  mounted() {
    let vm  = this
     if (!vm.$cookies.get("token")) {
          vm.$router.push({
              name: 'login'
          })
    }
    if(this.$cookies.get("clientData")){
      vm.clientName = this.$cookies.get("clientData").clientName
      vm.email = this.$cookies.get("clientData").emailAddress
      vm.mobile = this.$cookies.get("clientData").mobile
      vm.accessName = this.$cookies.get("clientData").accessName
    }
  },
  methods: {
    goMessage(){
      this.$router.push({ name: 'bulk-message' })
    },
    logout() {
      //localStorage.removeItem('userData')
      this.$cookies.remove("token");
      this.$cookies.remove("clientData");
      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>